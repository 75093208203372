<script
    lang="ts"
    setup
>
    type Props = {
        src?: string
        size?: number | string
        tag?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        src: undefined,
        size: 60,
        tag: 'div',
    })

    const style = computed<string>(() => (`--size: ${ getSizeValue(props.size) };`))
</script>

<template>
    <component
        :is="props.tag"
        :class="$style['avatar']"
        :style="style"
    >
        <img
            v-if="props.src"
            key="image"
            :src="props.src"
            alt="Image"
            :class="$style['avatar__image']"
            draggable="false"
        />

        <slot
            v-else
            key="fallback"
        />

        <slot
            key="after-content"
            name="after-content"
        />
    </component>
</template>

<style
    lang="sass"
    module
    scoped
>
    .avatar
        position: relative
        display: inline-flex
        justify-content: center
        align-items: center
        min-width: var(--size)
        min-height: var(--size)
        max-width: var(--size)
        max-height: var(--size)
        width: var(--size)
        height: var(--size)
        vertical-align: middle
        line-height: normal
        text-align: center
        border-radius: 50%
        background: #ebeef1

        &__image
            width: 100%
            height: 100%
            border-radius: inherit
</style>
